import React, { useEffect, useState } from 'react'

import { Content }  from '@components/Content'
import { Release }  from '@components/Release'
import { Seo }  from '@components/Seo'

const IndexPage = () => {
  const [data, setData] = useState({});

  useEffect(() => {
    (async() => {

      let { releases } = await import('@data/releases');

      setData(releases.slice().sort().reverse());
    })();
  }, []);

  return (
    <>
      <Content>
        <Seo title="Releases" description="Browse all records released on Nutriot Recordings" />
        {
          data.length && data.map(item => {
            return (
              <section
                key={item.id}
                className="item"
              >
                <Release {...item} />
              </section>

            )
          })
        }
      </Content>
    </>
  );
};

export default IndexPage
