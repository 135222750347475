import React, { useEffect, useState } from 'react';

import { Embed } from '@components/Embed';
import { useColorScheme } from '@lib/hooks';

const Bandcamp = props => {
  const [colorScheme, setColorScheme] = useState({});
  const [playerSize, setPlayerSize] = useState('large');

  useColorScheme(currentScheme => {
    if (props.size === 'small') {
      switch(currentScheme) {
        case 'light':
          setColorScheme({
            bgcol: 'ffffff',
            linkcol: '000000'
          });
          break;

        case 'dark':
          setColorScheme({
            bgcol: '000000',
            linkcol: 'ffffff'
          });
          break;

        default:
          throw Error('Could not determine color scheme');
      }
    } else {
      setColorScheme({
        bgcol: '333333',
        linkcol: 'ffffff'
      });
    }
  }, [setColorScheme]);

  useEffect(() => {
    const size = props?.size?.toLowerCase() || 'large';

    switch (size) {
      case 'small':
      case 'large':
        setPlayerSize(size);
        break;

      default:
        console.warn(`Invalid Bandcamp widget size: '${size}', falling back to 'large'`);
        setPlayerSize('large');
    }
  }, [props.size]);

  const src = props.url
    ? props.url
    : `https://bandcamp.com/EmbeddedPlayer/${props.bandcamp.type}=${props.bandcamp.id}/size=${playerSize}/bgcol=${colorScheme.bgcol}/linkcol=${colorScheme.linkcol}/minimal=true/transparent=true/`

  return (
    <Embed
      aspectRatio="1:1"
      frameBorder="0"
      loading="lazy"
      scrolling="no"
      seamless={true}
      src={src}
      style={props.style ? props.style : false}
      title={props.title ? props.title : false}
    />
  )
};

export default Bandcamp;
