import { useEffect, useState } from 'react';

function useStickyState(defaultValue, key) {
  const [value, setValue] = useState(() => {
    const stickyValue = window.localStorage.getItem(key);

    return stickyValue !== null
      ? JSON.parse(stickyValue)
      : defaultValue;
  });

  useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
}

const useColorScheme = callback => {

  useEffect(() => {
    function setColorScheme(matches) {
      const colorScheme = matches ? "dark" : "light";

      callback(colorScheme);
    }

    const prefersColorScheme = window.matchMedia('(prefers-color-scheme: dark)');
    setColorScheme(prefersColorScheme.matches);

    prefersColorScheme.addEventListener('change', e => {
      setColorScheme(e.matches);
    });

    // remove on unmount
    return () => window.removeEventListener("change", callback);
  }, [callback]);
};

export {
  useColorScheme,
  useStickyState
};
