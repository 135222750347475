import React from 'react';
import { useStaticQuery, graphql } from "gatsby"

import { Anchor } from '@components/Anchor';
import { Bandcamp } from '@components/Bandcamp';

import { isArray, setBackgroundStyle } from '@lib/util';

const Release = props => {
    const data = useStaticQuery(
      graphql`
        query {
          allImageSharp {
            edges {
              node {
                fluid(maxWidth: 300) {
                  ...GatsbyImageSharpFluid_withWebp
                  originalName
                }
              }
            }
          }
        }
      `
    );

    const title = `${props.artist} - ${props.title}`;
    const image = data.allImageSharp.edges.find( edge => {
      return edge.node.fluid.originalName === `${props.id}.webp`;
    });

    const random = props.bandcamp && isArray(props.bandcamp)
      ? Math.floor(Math.random() * props.bandcamp.length)
      : undefined;

    const bandcamp = isArray(props.bandcamp)
      ? props.bandcamp[random]
      : props.bandcamp;

    props.bandcamp.id = bandcamp.digital_id;
    props.bandcamp.type = bandcamp.type;

    const bandcampLink = bandcamp.url.startsWith('http://') || bandcamp.url.startsWith('https://')
      ? bandcamp.url
      : `https://nutriot.bandcamp.com/${bandcamp.type}/${bandcamp.url}`;

    return (
        <>
            <Bandcamp
                {...props}
                style={setBackgroundStyle(image)}
            />

            <h1>
              <Anchor
                href={bandcampLink}
                title={title}
              >
                {title}
              </Anchor>
            </h1>

            <p>
                <Anchor
                    href={bandcampLink}
                    title={`Buy ${title}`}
                >
                    Buy {props.formats.join(' or ')} release
                </Anchor>
            </p>
            { props?.vendors?.length ? (
              <>
                <p>Also available from:</p>
                <ul className="unstyled">
                  {
                    props.vendors.map(vendor => {
                        return (
                            <li key={vendor.name}>
                                <Anchor
                                    external={true}
                                    href={vendor.link}
                                    title={`Buy ${title} from ${vendor.name}`}
                                >
                                    {vendor.name}
                                </Anchor>
                            </li>
                        )
                    })
                  }
                </ul>
              </>
            ) : ''}
        </>
    )
};

export default Release;
